@import '~@studyportals/styles-abstracts/abstracts.scss';

.ModalOverlay .ModalWrapper.OptInPopUp {
	background: $GreyLL;
	min-height: auto;

	.CloseButton {
		top: 0.5rem;
		right: 0.5rem;
		z-index: $FirstFloor;
	}

	.ContentWrapper {
		@include PaddingAreas(0);
	}
}

.ModalOverlay .ModalWrapper.ComplexModal.AuthPopUp {
	.ContentWrapper {
		@include PaddingAreas(0);
	}

	.CloseButton {
		position: absolute;
		z-index: $FirstFloor;
		right: 1rem;
		top: 1rem;

		height: 3.2rem;
		width: 3.2rem;

		cursor: pointer;

		font-size: 1.4rem;
		color: $Grey;

		&:hover {
			color: $GreyD;
		}

		@include Breakpoint(Large, ExtraLarge) {
			display: none;
		}
	}
}
