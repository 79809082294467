@import '~@studyportals/styles-abstracts/abstracts.scss';

#authOptIn {
	.OptInHeader {
		position: relative;
		width: 100%;
		background: $White;
		overflow: hidden;
		text-align: center;

		h1 {
			@include MarginAreas(1, 0, 0, 0);
			@include TextStyle('Title100');
		}

		.OptInLetterbox {
			position: relative;
			@include MarginAreas(1, 0, 0, 0);
			height: 5rem;

			z-index: $FirstFloor;
		}

		.DiagonalDivider {
			position: absolute;
			bottom: -2.1rem;

			height: 5rem;
			width: calc(100% + 2rem);

			background: $GreyLL;
			transform: rotate(-5deg);
		}
	}

	.ContentContainer {
		@include PaddingAreas(1);
		box-sizing: border-box;
		@include TextStyle('BodyNarrow');
		background: $GreyLL;
		text-align: left;

		ul {
			@include TextStyle('Body');
			list-style: none;
			@include PaddingAreas(0);

			li {
				display: flex;
				align-items: center;

				&::before {
					content: '';
					background-color: $BrandOrange;
					display: inline-block;
					width: 0.5rem;
					height: 0.5rem;
					@include MarginAreas(0, 1, 0, 0);
				}
			}
		}

		.ActionContainer {
			display: flex;
			justify-content: space-between;
			@include MarginAreas(0.5, 0, 0, 0);

			button {
				@include TextStyle('Title100');
				display: flex;
				align-items: center;
				cursor: pointer;

				i {
					@include MarginAreas(0, 0, 0, 0.5);
				}

				&:first-child {
					@include PaddingAreas(0.5);
					color: $GreyDD;
					background: transparent;
					border: 0;
					font-weight: normal;
					transition: all 0.3s;
					border-radius: 3px;

					&:hover {
						background: rgba(36, 123, 160, 0.1);
					}
				}

				&:nth-child(2) {
					i {
						opacity: 0.5;
					}
				}
			}
		}
	}
}
